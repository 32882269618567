import { UnleashClient } from "unleash-proxy-client";

import Cookies from "react-cookies";

import { UserCookieVarProfile } from "../src/const/user";

import { FF_STORAGE, FF_NAME } from "../src/config/feature-flags";

import { getENV } from "../src/helpers/system";


const URL = process.env.REACT_APP_TOGGLE_ENDPOINT;
const KEY = process.env.REACT_APP_TOGGLE_KEY;

const FeatureFlagClient = async () => {
    const unleash = new UnleashClient({
        url: URL,
        clientKey: KEY,
        refreshInterval: 60,
        appName: getENV(),
        storageProvider: {
            save: (name, data) => {
                return FF_STORAGE.setItem(`${FF_NAME}-${name}`, JSON.stringify(data));
            },
            get: (name) => {
                return JSON.parse(FF_STORAGE.getItem(`${FF_NAME}-${name}`) || "{}");
            },
        },
    });

    const profile = Cookies.load(UserCookieVarProfile) || false;

    if (profile) {
        const { "representative-email": userId = false } = profile;

        if (userId) {
            unleash.updateContext({ userId });
        }
    }

    await unleash.start();

    return unleash;
};

export default FeatureFlagClient;
