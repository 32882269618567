import React from "react";

import { PagePaths } from "./../config/system";
import Compressor from "compressorjs";
import Markdown from "react-markdown";

export function getPagePath(pathname, params = {}, withInitialSlash = true) {
    let [name, idx = 0] = pathname instanceof Array ? pathname : [pathname];

    let pagePath = false;
    if (PagePaths[name]) {
        pagePath = Array.isArray(PagePaths[name]) ? PagePaths[name][idx] : PagePaths[name];

        const { state = false, ...customParams } = params;

        for (let param in customParams) {
            const value = customParams[param];

            pagePath = pagePath.replace(`:${param}`, value);
        }

        if (!withInitialSlash) {
            pagePath = pagePath.substr(1);
        }

        if (state) {
            pagePath = {
                pathname: pagePath,
                state,
            };
        }
    }

    return pagePath;
}

export function getStaticText(library, textId, params = {}, forceString = false) {
    const [text, textMod = false] = Array.isArray(textId) ? textId : [textId];

    let outputText = library[`${text}-${textMod}`] || library[text] || "";

    for (let varName in params) {
        const varValue = params[varName];
        const varRegEx = new RegExp(`%${varName}%`, "img");

        if (typeof varValue === "object") {
            const outputTextArray = outputText.split(varRegEx);
            outputText = [outputTextArray.shift()];

            for (let outputTextItem in outputTextArray) {
                outputText.push(varValue);
                outputText.push(outputTextArray[outputTextItem]);
            }
        } else {
            outputText = outputText.replace(varRegEx, varValue);
        }
    }

    let staticText = outputText;
    if (!forceString && outputText !== "") {
        let markdownText = outputText;
        if (Array.isArray(markdownText)) {
            markdownText = markdownText.join(" ");
        }

        staticText = (
            <Markdown
                unwrapDisallowed={true}
                source={markdownText}
                allowedTypes={["text", "strong", "emphasis", "link", "break"]}
            />
        );
    }

    return staticText;
}

export function sortObjectsByName(a, b) {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();

    let compareResult = 0;
    if (aName < bName) {
        compareResult = -1;
    }
    if (aName > bName) {
        compareResult = 1;
    }

    return compareResult;
}

export function compressImage(image, quality) {
    let compressedImage = image;
    if (/^image\//.test(image.type)) {
        compressedImage = new Promise((resolve, reject) => {
            return new Compressor(image, {
                quality,
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    }

    return compressedImage;
}

export function getENV() {
    const { NODE_ENV = "development", REACT_APP_API_ENDPOINT = "https://api.dev.gcp.seekcap.ph/v1/" } = process.env;
    // console.log('PROCESS_ENV: ', process.env);

    let env = NODE_ENV;
    if (/api.dev\./.test(REACT_APP_API_ENDPOINT)) {
        env = "development";
    } else if (/api.stg\./.test(REACT_APP_API_ENDPOINT)) {
        env = "staging";
    } else if (/api\./.test(REACT_APP_API_ENDPOINT)) {
        env = "production";
    }

    return env;
}
